import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { AdsGETApiAuth, AdsPOSTApi, AdsPUTApi } from "../../../Constant/api";
import { slugs } from "../../../Constant/slugs";
import { CSVLink } from "react-csv";

const columns = [
  {
    id: 1,
    name: "id",
    selector: (row) => <Link to={`${slugs.student_details}${row.slug}`}>{row.id}</Link>,
    sortable: true,
    reorder: true,
    compact: true,
    width: "70px"
  },
  {
    id: 31,
    name: "Title",
    selector: (row) => row.title.rendered,
    sortable: true,
    reorder: true,
    compact: true,
    width: "200px"
  },
  {
    id: 312,
    name: "Phone",
    selector: (row) => row.meta.user_phone_number,
    sortable: true,
    reorder: true,
    compact: true,
    width: "150px"
  },
  {
    id: 321,
    name: "batch",
    selector: (row) => row.meta.batch_name,
    sortable: true,
    reorder: true,
    compact: true,
    width: "120px"
  },
  {
    id: 32,
    name: "date",
    selector: (row) => row.modified.slice(0, 10),
    sortable: true,
    reorder: true,
    compact: true,
    width: "100px"
  },
  {
    id: 322,
    name: "role",
    selector: (row) => row.meta.user_role,
    sortable: true,
    reorder: true,
    compact: true,
    width: "150px"
  },
  {
    id: 313,
    name: "Email",
    selector: (row) => row.meta.user_email,
    sortable: true,
    reorder: true,
    compact: true,
    width: "200px"
  },

  // {
  //   id: 3,
  //   name: "View",
  //   right: true,
  //   cell: (row) => <Link to={`${slugs.student_details}${row.slug}`}>View</Link>,
  //   compact: true,
  //   width: "70px"
  // }
];

export default function AllStudents() {
  const [Projects1, setProjects1] = React.useState([])
  const [CustomersList, setCustomersList] = React.useState([])
  const [CustomersDownload, setCustomersDownload] = React.useState([])
  const [ImportData, setImportData] = React.useState([])
  const [Reload, setReload] = React.useState(false)
  const [SubscriberTotal, setSubscriberTotal] = React.useState(0)
  const [StudentTotal, setStudentTotal] = React.useState(0)
  const [AdminTotal, setAdminTotal] = React.useState(0)
  const [AllTotal, setAllTotal] = React.useState(0)
  const [serachName, setSearchName] = React.useState("")
  const [serachEmail, setSearchEmail] = React.useState("")
  const [serachPhone, setSearchPhone] = React.useState("")
  const [serachDate, setSearchDate] = React.useState("")
  const [batchList, setBatchList] = React.useState([])
  const [batchID, setBatchID] = React.useState("")
  const [batchName, setBatchName] = React.useState("")

  const [toStudentModel, setToStudentModal] = React.useState(false)
  const [ChangeToStudentModal, setChangeToStudentModal] = React.useState(false)
  const [ChangeToStudentBatchModal, setChangeToStudentBatchModal] = React.useState(false)
  const [Projects2, setProjects2] = React.useState([])
  const [choose, setChoose] = React.useState(0)

  const GetTotal = (Role, setDataLength, all) => {
    setReload(true)
    setProjects1([])
    const allT = !all ? {
      "filter[meta_query][1][key]": "user_role",
      "filter[meta_query][1][value][0]": `${Role}`,
      "filter[meta_query][1][compare]": "=",
      per_page: 1
    } : { per_page: 1 }
    AdsGETApiAuth(allT, slugs.db_slug_user_data)
      .then((res) => {
        setDataLength(res.headers["x-wp-total"])
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }
  const GetAllProjects = (params) => {
    setReload(true)
    setProjects1([])
    AdsGETApiAuth(params, slugs.db_slug_user_data)
      .then((res) => {
        setProjects1(res.data)
        setCustomersList(res.data)
        sortForDownload(res.data)
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }
  const sortForDownload = (DataArray) => {
    var dData = []
    for (let i = 0; i < DataArray.length; i++) {
      var item = DataArray[i]
      dData.push({
        id: item.id,
        name: item.title.rendered,
        phone_number: item.meta.user_phone_number,
        email: item.meta.user_email,
        login_user_name: item.meta.user_name,
        password: item.meta.user_password,
        role: item.meta.user_role
      })
    }
    setCustomersDownload(dData)
    console.log("done", dData)
  }
  useEffect(() => {
    GetAll()
  }, [])
  const GetAll = () => {
    GetAllProjects({ per_page: 10000, _fields: "id,date,modified,meta,title,slug" })
    // GetTotal("subscriber", setSubscriberTotal, false)
    // GetTotal("student", setStudentTotal, false)
    // GetTotal("author", setAdminTotal, false)
    // GetTotal("all", setAllTotal, true)
    BatchList()
  }
  const BatchList = () => {
    AdsGETApiAuth(
      { per_page: 100, _fields: "id,title,meta.batch_status,meta.batch_start_date,meta.batch_end_date" },
      slugs.db_slug_batch
    )
      .then((res) => {
        setBatchList(res.data)
      })
      .catch(e => {
        console.log(e)
      })
  }
  const [file, setFile] = React.useState();
  const fileReader = new FileReader();
  const handleOnChange = (e) => {
    // setFile(e.target.files[0]);
    e.preventDefault();
    const file = e.target.files[0]
    if (file) {
      fileReader.onload = function (event) {
        const csvOutput = event.target.result;
        const jsonData = convertCSVToJson(csvOutput);

        console.log(jsonData)
        var newData = []
        for (let i = 0; i < jsonData.length; i++) {
          const jsonDatai = jsonData[i]
          // console.log(jsonDatai)
          // const searchEmail = Projects1.filter(a => a.meta.user_name == jsonDatai["Login Name"])
          const searchEmail = Projects1.filter(a => a.meta.user_email == jsonDatai["Email"])
          console.log(jsonDatai, "asddasd", searchEmail.length)
          const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
          function validateEmail(email) {
            return emailRegex.test(email);
          }
          const isValidEmail = validateEmail(jsonDatai["Email"]);
          var chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
          var passwordLength = 12;
          var password = "";
          for (let l = 0; l <= passwordLength; l++) {
            var randomNumber = Math.floor(Math.random() * chars.length);
            password += chars.substring(randomNumber, randomNumber + 1);
          }
          if (isValidEmail)
            if (searchEmail.length == 0)
              newData.push({
                "title": { rendered: jsonDatai["Name"], },
                "status": "publish",
                "meta": {
                  "user_role": jsonDatai["User Role"],
                  "user_name": jsonDatai["Login Name"],
                  "user_password": jsonDatai["Password"] == "" ? password : jsonDatai["Password"],
                  "user_phone_number": jsonDatai["Phone Number"],
                  "user_email": jsonDatai["Email"],
                }
              })
        }
        // console.log
        setImportData(newData)
      };
      fileReader.readAsText(file);
    };
  }

  const convertCSVToJson = (csvData) => {
    const lines = csvData.split("\n");
    const headers = lines[0].replace("\r", "").split("\t");
    const result = [];
    // console.log(headers, lines[0])
    for (let i = 1; i < lines.length; i++) {
      var obj = {};
      const currentLine = lines[i].replace("\r", "").split("\t");
      // console.log(currentLine)
      for (let j = 0; j < headers.length; j++) {
        // obj[headers[j].trim()] = currentLine[j].trim();
        console.log(headers[j])
        var key = headers[j]
        obj = {
          ...obj,
          [key]: currentLine[j]
        }
      }
      // console.log(obj)

      result.push(obj);
    }
    // setImportData(result)
    return result;
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (file) {
      fileReader.onload = function (event) {
        const csvOutput = event.target.result;
        const jsonData = convertCSVToJson(csvOutput);

        console.log(jsonData)
        var newData = []
        for (let i = 0; i < jsonData.length; i++) {
          const jsonDatai = jsonData[i]
          console.log(jsonDatai, "asddasd")
          const searchEmail = Projects1.filter(a => a.meta.user_email == jsonDatai["Email"])
          if (jsonDatai["Name"] !== "" || jsonDatai["Name"] !== undefined)
            if (searchEmail.length == 0)
              newData.push({
                "title": { rendered: jsonDatai["Name"], },
                "meta": {
                  "user_role": jsonDatai["User Role"],
                  "user_name": jsonDatai["Login Name"],
                  "user_password": jsonDatai["Password"],
                  "user_phone_number": jsonDatai["Phone Number"],
                  "user_email": jsonDatai["Email"],
                }
              })
        }
        // console.log
        setImportData(newData)
      };
      fileReader.readAsText(file);
    }
  };
  const headers = [
    { label: "Name", key: "name" },
    { label: "Phone Number", key: "phone_number" },
    { label: "Email", key: "email" },
    { label: "Login Name", key: "login_user_name" },
    { label: "Password", key: "password" },
    { label: "User Role", key: "role" },
    { label: "User ID", key: "id" }
  ];
  const fileRefSingle = React.createRef();
  const date = new Date();

  const columns2 = [
    {
      id: 1,
      name: "id",
      selector: (row) => row.meta.user_id,
      sortable: true,
      reorder: true,
    },
    {
      id: 31,
      name: "Title",
      selector: (row) => row.title.rendered,
      sortable: true,
      reorder: true
    },
    {
      id: 312,
      name: "Phone",
      selector: (row) => row.meta.user_phone_number,
      sortable: true,
      reorder: true
    },
    {
      id: 313,
      name: "Email",
      selector: (row) => row.meta.user_email,
      sortable: true,
      reorder: true
    },
    {
      id: 314,
      name: "Password",
      selector: (row) => row.meta.user_password,
      sortable: true,
      reorder: true
    },
    {
      id: 32,
      name: "role",
      selector: (row) => row.meta.user_role,
      sortable: true,
      reorder: true
    },
  ];
  const columnsBatch = [
    {
      id: 1,
      name: "id",
      selector: (row) => row.id,
      sortable: true,
      reorder: true,
    },
    {
      id: 31,
      name: "Title",
      selector: (row) => row.title.rendered,
      sortable: true,
      reorder: true
    },
    {
      id: 312,
      name: "Total",
      selector: (row) => CustomersList.filter(a => a.meta.batch_id == row.id).length,
      sortable: true,
      reorder: true
    },
    {
      id: 312,
      name: "Status",
      selector: (row) => row.meta.batch_status,
      sortable: true,
      reorder: true
    },
    {
      id: 3,
      name: "View",
      right: true,
      cell: (row) => <div style={{ cursor: "pointer" }}
        onClick={() => {
          const ResultFilter = CustomersList.filter(a => a.meta.batch_id == row.id)
          setProjects1(ResultFilter)
          setChoose(0)
        }}
      >
        Choose
      </div>,

    }
  ];
  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>All Students</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / All Students
        </div>
      </div>
      <div style={{ display: "inline", cursor: "pointer" }}>
        <div style={{ display: "inline-block", margin: "0px 5px" }}>
          <a href={slugs.new_student}>
            <div className="sign-in-button-4" style={{ width: "150px", margin: "10px" }}
              onClick={() => {

              }}
            >Add New Student</div>
          </a>
        </div>
        <div style={{ display: "inline-block", margin: "0px 5px" }}
          onClick={() => {
            setImportData([])
            setToStudentModal(true)
          }}
        >
          Add Multiple |
        </div>
        <div style={{ display: "inline-block", margin: "0px 5px" }}>
          <CSVLink data={CustomersDownload} headers={headers} className="Link"
            filename={`StudentData-${date.toLocaleString()}.csv`}
          >

            Download CSV
          </CSVLink>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div style={{ display: "inline", cursor: "pointer" }}>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setProjects1(CustomersList)
                sortForDownload(CustomersList)
              }}
            >
              All ({CustomersList.length}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                const subscriberList = CustomersList.filter(a => a.meta.user_role == "subscriber")
                setProjects1(subscriberList)
                sortForDownload(subscriberList)
              }}
            >
              Subscribers ({CustomersList.filter(a => a.meta.user_role == "subscriber").length}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                const subscriberList = CustomersList.filter(a => a.meta.user_role == "teacher")
                setProjects1(subscriberList)
                sortForDownload(subscriberList)
              }}
            >
              Teacher ({CustomersList.filter(a => a.meta.user_role == "teacher").length}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                const subscriberList = CustomersList.filter(a => a.meta.user_role == "student")
                setProjects1(subscriberList)
                sortForDownload(subscriberList)
              }}
            >
              Students ({CustomersList.filter(a => a.meta.user_role == "student").length}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                const subscriberList = CustomersList.filter(a => a.meta.user_role == "author")
                setProjects1(subscriberList)
                sortForDownload(subscriberList)
              }}
            >
              Admin ({CustomersList.filter(a => a.meta.user_role == "author").length}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                const subscriberList = CustomersList.filter(a => a.meta.user_role == "author")
                setChoose(1)
              }}
            >
              Batch ({batchList.length}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px", cursor: "pointer" }}
              onClick={() => {
                setChangeToStudentModal(true)
              }}
            >
              Change Role |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px", cursor: "pointer" }}
              onClick={() => {
                setChangeToStudentBatchModal(true)
              }}
            >
              Assign Batch |
            </div>
          </div>
        </div>
        <div className="col-md-6 row">
          <div className="col-1" />
          <input className="col-2" style={{ margin: "1px 2px", padding: "3px 2px" }}
            value={serachName} placeholder="Name"
            onChange={(event) => {
              setSearchName(event.target.value)
              const subscriberList = CustomersList.filter(a => a.meta.user_name.includes(event.target.value))
              setProjects1(subscriberList)
              sortForDownload(subscriberList)
              // setProjects1(CustomersList.filter(a => a.meta.user_name.includes(event.target.value)))
            }}
          />
          <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
            value={serachEmail} placeholder="Email"
            onChange={(event) => {
              setSearchEmail(event.target.value)
              const subscriberList = CustomersList.filter(a => a.meta.user_email.includes(event.target.value))
              setProjects1(subscriberList)
              sortForDownload(subscriberList)
              // setProjects1(CustomersList.filter(a => a.meta.user_email.includes(event.target.value)))
            }}
          />
          <input className="col-2" style={{ margin: "1px 2px", padding: "3px 2px" }}
            value={serachPhone} placeholder="Phone"
            onChange={(event) => {
              setSearchPhone(event.target.value)
              const subscriberList = CustomersList.filter(a => a.meta.user_phone_number.includes(event.target.value))
              setProjects1(subscriberList)
              sortForDownload(subscriberList)
              // setProjects1(CustomersList.filter(a => a.meta.user_phone_number.includes(event.target.value)))
            }}
          />
          <input className="col-2" style={{ margin: "1px 2px", padding: "3px 2px" }}
            value={serachDate} placeholder="Date" type="date"
            onChange={(event) => {
              setSearchDate(event.target.value)
              const subscriberList = CustomersList.filter(a => a.modified.includes(event.target.value))
              setProjects1(subscriberList)
              sortForDownload(subscriberList)
              // setProjects1(CustomersList.filter(a => a.modified.includes(event.target.value)))
            }}
          />
        </div>
      </div>


      <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px" }}>
        {choose == 0 &&
          <DataTable
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 50, 100, 1000]}
            columns={columns}
            data={Projects1}
            defaultSortFieldId={1}
            defaultSortAsc={false}
            striped
            pagination
            selectableRows
            onSelectedRowsChange={(r) => {
              console.log("asdasd", r)
              setProjects2(r.selectedRows)
            }}
          />
        }
        {choose == 1 &&
          <DataTable
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 50, 100, 1000]}
            columns={columnsBatch}
            data={batchList}
            defaultSortFieldId={1}
            defaultSortAsc={false}
            striped
            pagination
            selectableRows={false}
          />
        }
      </div>
      {/* {JSON.stringify(ImportData)} */}
      {/* <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px" }}>
        <DataTable
          paginationPerPage={100}
          paginationRowsPerPageOptions={[10, 50, 100, 1000]}
          columns={columns}
          data={ImportData}
          defaultSortFieldId={1}
          defaultSortAsc={false}
          striped
          pagination
          selectableRows
        />
      </div> */}
      {Reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {toStudentModel &&
        <section className="modal" >
          <div className="row" style={{ overflow: "hidden" }}>
            <div className="col-md-2 col-1"></div>
            <div className="col-md-8 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setToStudentModal(false)
                    setImportData([])

                  }}
                >X</div>
                <div style={{ display: "none" }} >
                  <input ref={fileRefSingle}
                    type={"file"}
                    id={"csvFileInput"}
                    accept={".csv"}
                    onChange={handleOnChange}
                  />
                </div>
                <div className="sign-in-button-4" style={{ width: "150px", margin: "10px" }}
                  onClick={() => {
                    fileRefSingle.current.click()
                  }}
                >Import CSV</div>

                <div style={{ maxHeight: "50vh", overflow: "scroll", backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px", border: "1px solid #999" }}>
                  <table style={{ maxHeight: "50vh" }}>
                    <thead>
                      <tr>
                        <td>SN</td>
                        <td>Name</td>
                        <td>Email</td>
                        <td>Password</td>
                        <td>Phone Number</td>
                        <td>Role</td>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.values(ImportData).map((item, index) => (
                        <tr key={index}>
                          <TableRow row={item} index={index}
                            ImportData={ImportData} setImportData={setImportData} />
                        </tr>
                      ))}
                      {/* <TableRow row={item} index={index} /> */}

                      <tr>
                        <td colSpan={6} className="center" style={{ cursor: "pointer" }}
                          onClick={() => {
                            setImportData(
                              {
                                ...ImportData,
                                [Object.values(ImportData).length]: {
                                  "title": { rendered: "", },
                                  "meta": {
                                    "user_role": "",
                                    "user_name": "",
                                    "user_password": "",
                                    "user_phone_number": "",
                                    "user_email": "",
                                  }
                                }
                              }
                            )
                          }}
                        >
                          Add New
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="row center">
                  <div className="col-3 center " >
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        setToStudentModal(false)
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                  <div className="col-3 center">
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        const Projects = Object.values(ImportData)
                        for (let i = 0; i < Projects.length; i++) {
                          const item = {
                            title: Projects[i].title.rendered,
                            "status": "publish",
                            meta: Projects[i].meta,
                          }
                          var email = item.meta.user_email
                          const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
                          function validateEmail(email) {
                            return emailRegex.test(email);
                          }
                          var isValidEmail = validateEmail(item.meta.user_email);
                          var chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
                          var passwordLength = 12;
                          var password = "";
                          for (let l = 0; l <= passwordLength; l++) {
                            var randomNumber = Math.floor(Math.random() * chars.length);
                            password += chars.substring(randomNumber, randomNumber + 1);
                          }
                          var passwordConfirmed;
                          if (item.meta.user_password != "") {
                            passwordConfirmed = item.meta.user_password
                          }
                          else
                            isValidEmail = false

                          if (isValidEmail) {
                            const dataWithPassword = {
                              "username": item.meta.user_name,
                              "email": item.meta.user_email,
                              "password": passwordConfirmed,
                              "roles": item.meta.user_role
                            }
                            console.log(item)
                            setReload(true)
                            AdsPOSTApi(dataWithPassword, "users")
                              .then(res => {
                                const item = {
                                  title: Projects[i].title.rendered,
                                  "status": "publish",
                                  meta: {
                                    ...Projects[i].meta,
                                    "user_id": `${res.data.id}`
                                  }
                                }
                                console.log(item)
                                AdsPOSTApi(item, slugs.db_slug_user_data)
                                  .then((res) => {
                                    console.log(item)
                                  }).catch(err => {
                                    console.log(err)
                                  })

                              })
                              .catch(err => {
                                console.log(err)
                              })
                              .finally(() => {
                                if (i == Projects.length - 1) {
                                  alert(`${Projects.length} students added`)
                                  setReload(false)
                                  GetAll()
                                  setToStudentModal(false)
                                }
                              })
                          }
                          else
                            if (i == Projects.length - 1) {
                              alert(`${Projects.length} students added`)
                              setReload(false)
                              GetAll()
                              setToStudentModal(false)
                            }
                        }
                      }}
                    >
                      Add All Student
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {Reload &&
            <div className="modal" style={{ padding: "50vw" }}>
              <div className="dot-pulse"></div>
            </div>
          }
        </section>
      }
      {ChangeToStudentModal &&
        <section className="modal" >
          <div className="row" style={{ overflow: "hidden" }}>
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setChangeToStudentModal(false)
                  }}
                >X</div>
                Do you really want to Change all the user roles?<br />
                <div className="row">
                  <div className="col-3 center " >
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        setChangeToStudentModal(false)
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                  <div className="col-3 center">
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        setChangeToStudentModal(false)
                        for (let i = 0; i < Projects2.length; i++) {
                          setReload(true)
                          AdsPUTApi({
                            "roles": "subscriber"
                          }, "users", Projects2[i].meta.user_id)
                            .then((res) => {
                              AdsPUTApi({
                                meta: {
                                  "user_role": "subscriber"
                                }
                              }, slugs.db_slug_user_data, Projects2[i].id)
                                .then((res) => {
                                  if (i == Projects2.length - 1) {
                                    GetAll()
                                  }
                                })
                            })
                            .catch((err) => {
                              console.log(err)
                            })

                        }
                      }}
                    >
                      Change all to Subscriber
                    </div>
                  </div>
                  <div className="col-3 center">
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        setChangeToStudentModal(false)
                        for (let i = 0; i < Projects2.length; i++) {
                          setReload(true)
                          AdsPUTApi({
                            "roles": "student"
                          }, "users", Projects2[i].meta.user_id)
                            .then((res) => {
                              AdsPUTApi({
                                meta: {
                                  "user_role": "student"
                                }
                              }, slugs.db_slug_user_data, Projects2[i].id)
                                .then((res) => {
                                  if (i == Projects2.length - 1) {
                                    GetAll()
                                  }
                                })
                            })
                            .catch((err) => {
                              console.log(err)
                            })

                        }
                      }}
                    >
                      Change all to Student
                    </div>
                  </div>
                </div>
                <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px", border: "1px solid #999" }}>
                  <DataTable
                    paginationPerPage={100}
                    paginationRowsPerPageOptions={[10, 50, 100, 1000]}
                    columns={columns2}
                    data={Projects2}
                    defaultSortFieldId={1}
                    defaultSortAsc={false}
                    striped
                    // sortIcon={<SortIcon />}
                    pagination
                    selectableRows={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

      }

      {ChangeToStudentBatchModal &&
        <>
          <section className="modal" >
            <div className="row" style={{ overflow: "hidden" }}>
              <div className="col-md-3 col-1"></div>
              <div className="col-md-6 col-10  shadow bg-white">
                <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                  <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                    onClick={() => {
                      setChangeToStudentBatchModal(false)
                    }}
                  >X</div>
                  Do you really want to Change Batch of all selected users?<br />
                  {batchID}
                  <div className="row">
                    <div className="col-3 center " >
                      <div className="sign-in-button-4" style={{ width: "80%" }}
                        onClick={() => {
                          setChangeToStudentBatchModal(false)
                        }}
                      >
                        Cancel
                      </div>
                    </div>
                    <div className="col-3 center">
                      <div className="sign-in-button-4" style={{ width: "80%" }}
                        onClick={() => {
                          setChangeToStudentBatchModal(false)
                          for (let i = 0; i < Projects2.length; i++) {
                            setReload(true)
                            AdsPUTApi({
                              meta: {
                                "batch_id": "",
                                "batch_name": "",
                              }
                            }, slugs.db_slug_user_data, Projects2[i].id)
                              .then((res) => {
                                if (i == Projects2.length - 1) {
                                  GetAll()
                                }
                              })
                          }
                        }}
                      >
                        Reset Batch
                      </div>
                    </div>
                    <div className="col-3 center">
                      <div className="sign-in-button-4" style={{ width: "80%" }}
                        onClick={() => {
                          setChangeToStudentBatchModal(false)
                          for (let i = 0; i < Projects2.length; i++) {
                            setReload(true)
                            AdsPUTApi({
                              meta: {
                                "batch_id": batchID,
                                "batch_name": batchName,
                              }
                            }, slugs.db_slug_user_data, Projects2[i].id)
                              .then((res) => {
                                if (i == Projects2.length - 1) {
                                  GetAll()
                                }
                              })
                          }
                        }}
                      >
                        Set Selected Batch
                      </div>
                    </div>
                  </div>
                  <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px", border: "1px solid #999" }}>
                    <table>
                      <tbody>
                        {batchList.map((item, index) => (
                          <tr className="row bg-white shadow" key={index}
                            style={{ cursor: "pointer", border: "1px solid #999" }}
                            onClick={() => {
                              if (batchID != item.id) {
                                setBatchID(`${item.id}`)
                                setBatchName(item.title.rendered)
                              }
                              else {
                                setBatchID(``)
                                setBatchName("")
                              }
                            }}
                          >
                            <td className="col-1">
                              {batchID != item.id ?
                                <i className="fa fa-square-o" />
                                :
                                <i className="fa fa-check-square-o" />
                              }
                            </td>
                            <td className="col-2" >
                              {item.id}
                            </td>
                            <td className="col-3">
                              {item.title.rendered}
                            </td>
                            <td className="col-3">
                              {item.meta.batch_start_date}
                            </td>
                            <td className="col-3">
                              {item.meta.batch_end_date}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <table>
            <tbody>
              {batchList.map((item, index) => (
                <tr className="row bg-white shadow" key={index}
                  style={{ cursor: "pointer", border: "1px solid #999" }}
                  onClick={() => {
                  }}
                >
                  <td className="col-1">
                    {batchID !== item.id ?
                      <i className="fa fa-square-o" />
                      :
                      <i className="fa fa-check-square-o" />
                    }
                  </td>
                  <td className="col-2" >
                    {item.id}
                  </td>
                  <td className="col-3">
                    {item.title.rendered}
                  </td>
                  <td className="col-3">
                    {item.meta.batch_start_date}
                  </td>
                  <td className="col-3">
                    {item.meta.batch_end_date}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      }
      {/* {JSON.stringify(ImportData)} */}
    </div >
  )
}

const TableRow = ({ row, index, ImportData, setImportData }) => {
  const [name, setName] = React.useState(row.title.rendered)
  const [LoginName, setLoginName] = React.useState(row.meta.user_name)
  const [email, setEmail] = React.useState(row.meta.user_email)
  const [PassWord, setPassword] = React.useState(row.meta.user_password)
  const [PhoneNumber, setPhoneNumber] = React.useState(row.meta.user_phone_number)
  const [Role, setRole] = React.useState(row.meta.user_role)
  return (
    <>
      <td>{index + 1}</td>
      <td>
        <input className="input-common-2"
          value={name} placeholder="User Name"
          onChange={(event) => {
            setName(event.target.value)
            console.log(JSON.stringify(ImportData[index]))

            const ImportData2 = {
              ...ImportData,
              [index]: {
                title: { rendered: event.target.value },
                meta: { ...ImportData[index].meta, user_name: event.target.value }
              }
            }
            setImportData(ImportData2)
          }}
        />
      </td>
      <td>
        <input className="input-common-2"
          value={email} placeholder="Email"
          onChange={(event) => {
            setEmail(event.target.value)
            const ImportData2 = {
              ...ImportData,
              [index]: {
                title: ImportData[index].title,
                meta: { ...ImportData[index].meta, user_email: event.target.value }
              }
            }
            setImportData(ImportData2)
          }}
        />
      </td>

      <td>
        <div style={{ display: "inline" }}>
          <div style={{ display: "inline-block" }}>

            <input className="input-common-2 col-6"
              value={PassWord} placeholder="Password"
              onChange={(event) => {
                setPassword(event.target.value)
                const ImportData2 = {
                  ...ImportData,
                  [index]: {
                    title: ImportData[index].title,
                    meta: { ...ImportData[index].meta, user_password: event.target.value }
                  }
                }
                setImportData(ImportData2)
              }}
            />
            <div style={{ display: "inline-block", cursor: "pointer" }}
              onClick={() => {
                var chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
                var passwordLength = 12;
                var password = "";
                for (let l = 0; l <= passwordLength; l++) {
                  var randomNumber = Math.floor(Math.random() * chars.length);
                  password += chars.substring(randomNumber, randomNumber + 1);
                }
                setPassword(password)
                const ImportData2 = {
                  ...ImportData,
                  [index]: {
                    title: ImportData[index].title,
                    meta: { ...ImportData[index].meta, user_password: password }
                  }
                }
                setImportData(ImportData2)
              }}
            >
              &nbsp;Generate
            </div>
          </div>
        </div>
      </td>
      <td>
        <input className="input-common-2"
          value={PhoneNumber} placeholder="Phone Number"
          onChange={(event) => {
            setPhoneNumber(event.target.value)
            const ImportData2 = {
              ...ImportData,
              [index]: {
                title: ImportData[index].title,
                meta: { ...ImportData[index].meta, user_phone_number: event.target.value }
              }
            }
            setImportData(ImportData2)
          }}
        />
      </td>
      <td>
        <CustomButton setData={setRole} Data={Role} DataValue={"subscriber"}
          index={index} ImportData={ImportData} setImportData={setImportData} />
        <CustomButton setData={setRole} Data={Role} DataValue={"student"}
          index={index} ImportData={ImportData} setImportData={setImportData} />
      </td>
      {/* <td onClick={() => {
      }}>
        Done
      </td> */}
    </>
  )
}

const CustomButton = ({ setData, Data, DataValue, ImportData, setImportData, index }) => {
  return (
    <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
      onClick={() => {
        setData(DataValue)
        const ImportData2 = {
          ...ImportData,
          [index]: {
            title: ImportData[index].title,
            meta: { ...ImportData[index].meta, user_role: DataValue }
          }
        }
        setImportData(ImportData2)
      }}
    >
      <div className={Data == DataValue ? "active-option-2" : "passive-option-2"}>
        {DataValue}
      </div>
    </button>
  )
}