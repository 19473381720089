import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { AdsGETApiAuth } from "../../../Constant/api";
import { slugs } from "../../../Constant/slugs";
import { useSelector, useDispatch } from "react-redux";
import { logout } from '../../../redux/action';
const columns = [
  {
    id: 1,
    name: "id",
    selector: (row) => row.id,
    sortable: true,
    reorder: true,
    compact: true,
    width: "150px"
  },
  {
    id: 31,
    name: "Title",
    selector: (row) => row.title.rendered,
    sortable: true,
    reorder: true,
    compact: true,
  },
  {
    id: 33,
    name: "Availability",
    selector: (row) => row.meta.available_unavailable,
    sortable: true,
    reorder: true,
    width: "150px"

  },
  {
    id: 34,
    name: "Type",
    selector: (row) => row.meta.exam_type,
    sortable: true,
    reorder: true
  },
  {
    id: 3,
    name: "View",
    cell: (row) => <Link to={`${slugs.daily_exam_details}${row.slug}`}>View</Link>,

  }
];

export default function AllDailyExamTeacher() {
  const [Projects1, setProjects1] = React.useState([])
  const [CustomersList, setCustomersList] = React.useState([])
  const [Reload, setReload] = React.useState(false)
  const [usersData, setUsersData] = React.useState({ meta: { number_of_exams: "0" } });
  const LoginState = useSelector((state) => state.LoginState);
  const dispatch = useDispatch()
  const GetAllProjects = (params, verifiedExamList) => {
    setReload(true)
    setProjects1([])
    AdsGETApiAuth(params, slugs.db_slug_daily_exam)
      .then((res) => {
        // setProjects1(res.data)
        // setCustomersList(res.data)

        const examList = res.data
        var verified = [];
        var unverified = [];
        for (let i = 0; i < examList.length; i++) {
          if (verifiedExamList.filter(a => a.exam_id == examList[i].id).length !== 0)
            verified.push(examList[i])
          else
            unverified.push(examList[i])
          setProjects1(verified)
          setCustomersList(verified)
        }
        setReload(false)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }
  useEffect(() => {
    AdsGETApiAuth({
      status: "any",
      "filter[meta_query][1][key]": "user_id",
      "filter[meta_query][1][value][0]": LoginState.userID,
      "filter[meta_query][1][compare]": "=",
      _fields: "meta"
    }, "user_data")
      .then((response) => {
        if (response.data.length !== 0) {
          setUsersData(response.data[0])
          var verifiedExamListCache = response.data[0].meta.verified_exam_list
          var verifiedExamList;
          if (verifiedExamListCache == "")
            verifiedExamList = [];
          else
            verifiedExamList = JSON.parse(verifiedExamListCache)
        }
        GetAllProjects({
          per_page: 1000,
          _fields: "id,title,slug,date,meta.exam_time,meta.available_unavailable,meta.exam_type,meta.batch_name"
        },
          verifiedExamList
        )
      })
      .catch(e => {
        if (e.message.includes("Request failed with status code 500"))
          dispatch(logout())
      })
  }, [])

  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>All Exams</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / All Exams
        </div>
      </div>
      {/* <a href={slugs.new_daily_exam}>
        <div className="sign-in-button-4" style={{ width: "150px", margin: "10px" }}
          onClick={() => {

          }}
        >Add New Daily Exam</div>
      </a> */}
      <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px" }}>
        <DataTable
          columns={columns}
          data={CustomersList}
          defaultSortFieldId={1}
          defaultSortAsc={false}
          striped
          // sortIcon={<SortIcon />}
          pagination
          selectableRows
          paginationPerPage={100}
          paginationRowsPerPageOptions={[10, 50, 100, 1000]}
        />
      </div>
      {Reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}